import React, { CSSProperties, ReactNode, useMemo } from "react"
import { Page } from "../shared/Page/Page"
import Helmet from "react-helmet"
import styled from "styled-components"
import { H1, H2 } from "../shared/Ui/Typography/Typography"
import { FlatButton } from "../shared/Ui/FlatButton/FlatButton"
import { Link } from "gatsby"
import { TextButton } from "../shared/Ui/TextButton/TextButton"

// @ts-ignore
import PlansImageBackground from "../images/pricing/plans-bg.png"

import CountryData from "../data/countries-by-continent.json"
import { Chip } from "../shared/Chip/Chip"
import { Constrained } from "../shared/Constrained/Constrained"
import media from "css-in-js-media"

const Container = styled.div`
    margin-bottom: 30rem;

    div.top-section {
        background-size: 31%;
        background-repeat: repeat;
        background-position: center;
        box-shadow: rgb(0 0 0 / 6%) 0 -20rem 20rem -20px inset;
    }

    section.head {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 30rem 35rem;

        > div {
            max-width: 490rem;
        }
    }

    section.plans {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20rem;
        margin-bottom: 100rem;
        padding-bottom: 100rem;
        padding-top: 40rem;
        border-bottom: 1rem solid ${({ theme }) => theme.borders.light};

        > div {
            display: flex;
            flex-direction: column;
            padding: 30rem 35rem;
            max-width: 300rem;
            border-radius: 10rem;
            border: 1rem solid ${({ theme }) => theme.borders.normal};
            gap: 10rem;
            background: #fff;
            position: relative;

            box-shadow: rgba(149, 157, 165, 0.2) 0 8rem 24rem;

            &:nth-of-type(2) {
                box-shadow: rgba(149, 157, 165, 0.2) 0 8rem 24rem,
                    0 0 0 3rem #2563eb;
                transform: scale(1.05);
            }

            .banner {
                background: #2563eb;
                margin: -30rem -15rem -10rem;
                border-radius: 10rem 10rem 0 0;
                color: #fff;
                text-align: center;
                padding: 10rem;
                font-family: Gilroy-SemiBold, sans-serif;
                position: relative;
                top: -40rem;
                z-index: -1;
            }

            .name {
                font-size: 25rem;
                color: ${({ theme }) => theme.text.darker};
                font-weight: 500;
                font-family: Gilroy-Bold, sans-serif;
            }

            .description {
                font-size: 16rem;
                line-height: 26rem;
                color: ${({ theme }) => theme.text.light};
            }

            .pricing {
                display: flex;
                align-items: flex-end;
                margin: 20rem 0;

                .figure {
                    font-family: Gilroy-ExtraBold, sans-serif;
                    font-size: 40rem;
                    color: ${({ theme }) => theme.text.darker};
                }

                .period {
                    color: ${({ theme }) => theme.text.lighter};
                    font-weight: 500;
                    line-height: 33rem;
                    padding: 0 4rem;
                }
            }

            .separator {
                margin: 20rem -35rem;
                height: 1rem;
                background: ${({ theme }) => theme.borders.normal};
            }

            .subheader {
                color: ${({ theme }) => theme.text.lighter};
                font-weight: 500;
                font-size: 14rem;
                text-transform: uppercase;
            }

            .included {
                flex-grow: 4;

                ul {
                    list-style: none;
                    margin-left: 0;
                    padding-left: 0;
                }

                li {
                    display: flex;
                    align-items: flex-start;
                    gap: 10rem;
                    margin: 10rem 0;
                    line-height: 24rem;

                    color: ${({ theme }) => theme.text.light};
                    font-size: 15rem;

                    svg {
                        margin-top: 2rem;
                    }
                }
            }
        }
    }

    section.call-costs {
        .head {
            text-align: center;
            justify-content: center;
            padding: 30rem 35rem;
            margin: 0 auto;
            max-width: 500rem;
        }

        .popular-destinations {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            gap: 20rem;

            .flags {
                display: flex;
                justify-content: center;
                gap: 10rem;
                margin-bottom: 20rem;

                .flag {
                    display: flex;
                    flex-direction: column;
                    gap: 10rem;
                    padding: 10rem 15rem;
                    border-radius: 5rem;
                    transition: all ease-in-out 150ms;
                    box-shadow: rgba(149, 157, 165, 0.2) 0 0 0;

                    &:hover {
                        // background: ${({ theme }) => theme.surfaces.light};
                        box-shadow: rgba(149, 157, 165, 0.2) 0 8rem 24rem;
                    }

                    .image {
                        border-radius: 5rem;
                    }

                    .name {
                        color: ${({ theme }) => theme.text.light};
                        font-size: 16rem;
                    }
                }
            }
        }

        .regions-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            > div {
                flex: 1;
                max-width: 33%;
                text-align: left;

                h3 {
                    color: ${({ theme }) => theme.text.normal};
                }

                ul {
                    list-style: none outside none;
                    margin: 0;
                    padding: 0;

                    li {
                        margin: 0;
                        padding: 0;
                        margin-bottom: 10rem;
                        white-space: nowrap;
                    }
                }
            }
        }
    }

    .features-grid {
        display: flex;
        flex-direction: column;
        gap: 80rem;

        > section {
            display: flex;
            flex-direction: column;

            .row {
                display: flex;
                flex-direction: row;
                width: 100%;
                border-bottom: 1rem solid ${({ theme }) => theme.borders.light};

                &.clickable:hover {
                    background: ${({ theme }) => theme.surfaces.light};
                }

                > .cell {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    height: 40rem;
                    color: ${({ theme }) => theme.text.light};
                    font-size: 14rem;
                    font-weight: 500;
                    flex-direction: column;

                    &.left {
                        align-items: flex-start;
                        text-align: left;

                        .ShoutChip-root {
                            margin-left: 10rem;
                        }
                    }

                    &.primary {
                        color: ${({ theme }) => theme.primary};
                        font-family: "Gilroy-SemiBold", sans-serif;
                        font-weight: unset;
                    }

                    .mobile-plan-name {
                        font-size: 10rem;
                        text-transform: uppercase;
                        display: none;
                        color: ${({ theme }) => theme.text.lighter};
                    }
                }
            }
        }
    }

    ${media("<desktop")} {
        section.plans {
            > div {
                max-width: unset;
                width: 100%;
                transform: scale(1) !important;

                .banner {
                    display: none;
                }

                box-shadow: rgba(149, 157, 165, 0.2) 0 8rem 24rem !important;
            }
        }

        .features-grid:not(.ms) {
            > section {
                .row {
                    flex-wrap: wrap;
                    padding: 15rem 0;

                    > .cell {
                        text-align: left;
                        align-items: flex-start;
                        gap: 10rem;
                        height: unset;

                        &:not(.plan-header):first-of-type {
                            flex: 1 100%;
                        }

                        .mobile-plan-name {
                            display: block;
                        }

                        &.large-only {
                            display: none;
                        }

                        &.left {
                            font-family: Gilroy-SemiBold, sans-serif;
                            margin-bottom: 10rem;
                            font-size: 16rem;
                        }
                    }
                }
            }
        }
    }
`

const renderFeatureGridItem: (item: ReactNode) => ReactNode = (item) => {
    return !!item ? (
        typeof item === "boolean" ? (
            <Check />
        ) : (
            item
        )
    ) : (
        <Check style={{ opacity: 0 }} />
    )
}

const PLAN_DETAILS: Array<{
    sectionName: ReactNode
    features: Array<{
        featureName: ReactNode
        standard?: ReactNode
        pro?: ReactNode
        custom?: ReactNode
    }>
}> = [
    {
        sectionName: "Core features",
        features: [
            {
                featureName: "Calling and messaging (US & Canada)",
                standard: "Unlimited",
                pro: "Unlimited",
                custom: "Unlimited",
            },
            {
                featureName: "Calling and messaging (International)",
                standard: "At cost",
                pro: "Unlimited",
                custom: "Unlimited",
            },
            {
                featureName: "Phone numbers included",
                standard: "1",
                pro: "1",
                custom: "1",
            },
            {
                featureName: "Additional phone numbers",
                standard: "At cost",
                pro: "At cost",
                custom: "Below cost",
            },
            {
                featureName: "Port existing phone number",
                standard: true,
                pro: true,
                custom: true,
            },
        ],
    },
    {
        sectionName: "Calling features",
        features: [
            {
                featureName: "Recording",
                standard: "Unlimited",
                pro: "Unlimited",
                custom: "Unlimited",
            },
            {
                featureName: "Inbound Flows",
                standard: "Up to 3",
                pro: "Unlimited",
                custom: "Unlimited",
            },
            {
                featureName: "Business hours",
                standard: true,
                pro: true,
                custom: true,
            },
            {
                featureName: "Voicemail and transcription",
                standard: true,
                pro: true,
                custom: true,
            },
            {
                featureName: "Call logging",
                standard: true,
                pro: true,
                custom: true,
            },
            {
                featureName: "Progressive dialler",
                standard: true,
                pro: true,
                custom: true,
            },
            {
                featureName: "After call work",
                standard: true,
                pro: true,
                custom: true,
            },
            {
                featureName: "IVR (Auto attendant)",
                standard: true,
                pro: true,
                custom: true,
            },
            {
                featureName: "Call transfers",
                standard: true,
                pro: true,
                custom: true,
            },
            {
                featureName: "Hold and mute remote parties",
                standard: true,
                pro: true,
                custom: true,
            },
            {
                featureName: "Caller ID",
                standard: true,
                pro: true,
                custom: true,
            },
            {
                featureName: "Round robin inbound",
                standard: true,
                pro: true,
                custom: true,
            },
            {
                featureName: "Conference calling",
                pro: <Chip body={"Add on"} variant={"info"} />,
                custom: true,
            },
            {
                featureName: (
                    <>
                        Call monitoring{" "}
                        <Chip body={"Coming soon"} variant={"warning"} />
                    </>
                ),
                pro: <Chip body={"Add on"} variant={"info"} />,
                custom: true,
            },
            {
                featureName: (
                    <>
                        Agent whispering{" "}
                        <Chip body={"Coming soon"} variant={"warning"} />
                    </>
                ),
                pro: <Chip body={"Add on"} variant={"info"} />,
                custom: true,
            },
        ],
    },
    {
        sectionName: "Messaging",
        features: [
            {
                featureName: "SMS messages",
                standard: true,
                pro: true,
                custom: true,
            },
            {
                featureName: (
                    <>
                        MMS messages
                        <Chip body={"Coming soon"} variant={"warning"} />
                    </>
                ),
                standard: true,
                pro: true,
                custom: true,
            },
            {
                featureName: "Delivery receipts",
                standard: true,
                pro: true,
                custom: true,
            },
            {
                featureName: "Conversation assignment",
                standard: true,
                pro: true,
                custom: true,
            },
            {
                featureName: "Saved replies",
                standard: true,
                pro: true,
                custom: true,
            },
            {
                featureName: "Inline notes",
                standard: true,
                pro: true,
                custom: true,
            },
            {
                featureName: "Zapier SMS",
                standard: true,
                pro: true,
                custom: true,
            },
            {
                featureName: "API SMS",
                standard: <Chip body={"Add on"} variant={"info"} />,
                pro: <Chip body={"Add on"} variant={"info"} />,
                custom: <Chip body={"Add on"} variant={"info"} />,
            },
            {
                featureName: (
                    <>
                        Burst campaigns
                        <Chip body={"Coming soon"} variant={"warning"} />
                    </>
                ),
                pro: true,
                custom: true,
            },
        ],
    },
    {
        sectionName: "Collaboration",
        features: [
            {
                featureName: "Call scripts",
                standard: "Up to 3",
                pro: "Unlimited",
                custom: "Unlimited",
            },
            {
                featureName: "Workflows and automations",
                standard: true,
                pro: true,
                custom: true,
            },
            {
                featureName: "Call tags",
                standard: true,
                pro: true,
                custom: true,
            },
            {
                featureName: "Call scheduling",
                standard: true,
                pro: true,
                custom: true,
            },
            {
                featureName: "Account owners",
                standard: true,
                pro: true,
                custom: true,
            },
            {
                featureName: "Teams",
                standard: true,
                pro: true,
                custom: true,
            },
            {
                featureName: "Analytics and reporting",
                standard: true,
                pro: true,
                custom: true,
            },
            {
                featureName: "Account permissions",
                standard: true,
                pro: true,
                custom: true,
            },
            {
                featureName: "Contact notes",
                standard: true,
                pro: true,
                custom: true,
            },
            {
                featureName: "Call lists",
                standard: true,
                pro: true,
                custom: true,
            },
        ],
    },
    {
        sectionName: "Integrations",
        features: [
            {
                featureName: "Zapier",
                standard: true,
                pro: true,
                custom: true,
            },
            {
                featureName: "Hubspot",
                standard: true,
                pro: true,
                custom: true,
            },
            {
                featureName: "Pipedrive",
                standard: true,
                pro: true,
                custom: true,
            },
            {
                featureName: "Google Meet",
                standard: true,
                pro: true,
                custom: true,
            },
            {
                featureName: "Zoom",
                standard: true,
                pro: true,
                custom: true,
            },
            {
                featureName: "API access",
                standard: true,
                pro: true,
                custom: true,
            },
            {
                featureName: (
                    <>
                        Salesforce
                        <Chip body={<>Coming soon</>} variant={"warning"} />
                    </>
                ),
                standard: true,
                pro: true,
                custom: true,
            },
            {
                featureName: (
                    <>
                        Gong
                        <Chip body={<>Coming soon</>} variant={"warning"} />
                    </>
                ),
                standard: true,
                pro: true,
                custom: true,
            },
        ],
    },
    {
        sectionName: "Support",
        features: [
            {
                featureName: "Email support",
                standard: true,
                pro: true,
                custom: true,
            },
            {
                featureName: "Live chat support",
                standard: true,
                pro: true,
                custom: true,
            },
            {
                featureName: "Phone support",
                standard: true,
                pro: true,
                custom: true,
            },
            {
                featureName: "Priority support",
                custom: true,
            },
            {
                featureName: "Slack connect channel",
                custom: true,
            },
        ],
    },
]

const Check = ({ style = {} }: { style?: CSSProperties }) => {
    return (
        <svg
            className="flex-shrink-0 h-5 w-5 text-green-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="rgb(16,185,129)"
            aria-hidden="true"
            focusable={"false"}
            style={{
                minWidth: "20rem",
                height: "20rem",
                ...style,
            }}
        >
            <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clipRule="evenodd"
            />
        </svg>
    )
}

const Pricing = ({ location }) => {
    const countryByContinent: { [continentName: string]: typeof CountryData } =
        useMemo(() => {
            return CountryData.reduce((acc, curr) => {
                let newAcc = { ...acc }

                if (newAcc[curr.Continent_Name] === undefined) {
                    newAcc[curr.Continent_Name] = []
                }

                newAcc[curr.Continent_Name].push(curr)

                return newAcc
            }, {})
        }, [])

    return (
        <Page
            location={location}
            constrainedHeader
            constrainedCta
            constrainedFooter
        >
            <Helmet>
                <title>Pricing</title>
            </Helmet>
            <Container>
                <div className={"top-section"}>
                    <section className={"head"}>
                        <div>
                            <H1>Pricing Plans</H1>
                            <H2>No lock ins - No minimums - Cancel anytime</H2>
                        </div>
                    </section>
                    <section className={"plans"}>
                        <div>
                            <div className={"name"}>Starter</div>
                            <div className={"description"}>
                                Everything you need to get calling
                            </div>
                            <div className={"pricing"}>
                                <div className={"figure"}>$30</div>
                                <div className={"period"}>/mo per dialler</div>
                            </div>

                            <div className={"included"}>
                                <div className={"subheader"}>
                                    What's included
                                </div>

                                <ul>
                                    <li>
                                        <Check />
                                        <div>Free 14 day trial</div>
                                    </li>
                                    <li>
                                        <Check />
                                        <div>One free phone number</div>
                                    </li>
                                    <li>
                                        <Check />
                                        Unlimited outbound calls to US numbers
                                    </li>
                                    <li>
                                        <Check />
                                        Unlimited inbound calls
                                    </li>
                                    <li>
                                        <Check />
                                        Unlimited recordings with 1 year storage
                                    </li>
                                    <li>
                                        <Check />
                                        <div>
                                            Built in{" "}
                                            <Link
                                                to={
                                                    "/collaboration/contact-management"
                                                }
                                                tabIndex={-1}
                                            >
                                                <TextButton color={"primary"}>
                                                    contact management
                                                </TextButton>
                                            </Link>
                                        </div>
                                    </li>
                                    <li>
                                        <Check />
                                        <div>
                                            Three{" "}
                                            <Link
                                                to={"/features/call-scripts"}
                                                tabIndex={-1}
                                            >
                                                <TextButton color={"primary"}>
                                                    call scripts
                                                </TextButton>
                                            </Link>
                                        </div>
                                    </li>
                                    <li>
                                        <Check />
                                        <div>Zapier and CRM extensions</div>
                                    </li>
                                </ul>
                            </div>

                            <div className={"separator"} />

                            <a
                                href={
                                    "https://app.23shout.com?utm_source=marketing"
                                }
                            >
                                <FlatButton
                                    color={"primary"}
                                    variant={"flat"}
                                    size={"larger"}
                                    justify={"center"}
                                    fontWeight={600}
                                    fullWidth
                                >
                                    Get started
                                </FlatButton>
                            </a>
                        </div>

                        <div>
                            <div className={"banner"}>Most popular</div>
                            <div className={"name"}>Pro</div>
                            <div className={"description"}>
                                All the features in starter plus unlimited
                                outbound calls
                            </div>

                            <div className={"pricing"}>
                                <div className={"figure"}>$70</div>
                                <div className={"period"}>/mo per dialler</div>
                            </div>

                            <div className={"included"}>
                                <div className={"subheader"}>
                                    What's included
                                </div>

                                <ul>
                                    <li>
                                        <div
                                            style={{
                                                color: "#2563eb",
                                                fontFamily:
                                                    "Gilroy-SemiBold, sans-serif",
                                            }}
                                        >
                                            Everything in standard, plus...
                                        </div>
                                    </li>
                                    <li>
                                        <Check />
                                        Unlimited outbound & inbound calls to
                                        all countries
                                    </li>
                                    <li>
                                        <Check />
                                        Unlimited recordings with 7 year storage
                                    </li>
                                    <li>
                                        <Check />
                                        <div>
                                            Unlimited{" "}
                                            <Link
                                                to={"/features/call-scripts"}
                                                tabIndex={-1}
                                            >
                                                <TextButton color={"primary"}>
                                                    call scripts
                                                </TextButton>
                                            </Link>
                                        </div>
                                    </li>
                                    <li>
                                        <Check />
                                        <div>All extensions included</div>
                                    </li>
                                </ul>
                            </div>

                            <div className={"separator"} />

                            <a
                                href={
                                    "https://app.23shout.com?utm_source=marketing"
                                }
                            >
                                <FlatButton
                                    color={"primary"}
                                    variant={"flat"}
                                    size={"larger"}
                                    justify={"center"}
                                    fontWeight={600}
                                    fullWidth
                                >
                                    Get started
                                </FlatButton>
                            </a>
                        </div>

                        <div>
                            <div className={"name"}>Enterprise</div>
                            <div className={"description"}>
                                Need something specific? Let's talk.
                            </div>
                            <div className={"pricing"}>
                                <div className={"period"}></div>
                            </div>

                            <div className={"included"}>
                                <div className={"subheader"}>Contact us</div>

                                <ul>
                                    <li>
                                        For custom solutions and large scale
                                        deployments we can help design a pricing
                                        model that works for you.
                                    </li>
                                    <li>
                                        Contact our team to discuss consolidated
                                        billing, custom integrations, staff
                                        training, and more.
                                    </li>
                                </ul>
                            </div>

                            <div className={"separator"} />

                            <Link to={"/support/contact"} tabIndex={-1}>
                                <FlatButton
                                    color={"primary"}
                                    variant={"outlined"}
                                    size={"larger"}
                                    justify={"center"}
                                    fontWeight={600}
                                    fullWidth
                                >
                                    Contact the team
                                </FlatButton>
                            </Link>
                        </div>
                    </section>
                </div>
                <Constrained>
                    <div>
                        <div className={"features-grid"}>
                            {PLAN_DETAILS.map((category, index) => (
                                <section key={`ps-${index}`}>
                                    <div className={"row plan-header"}>
                                        <div className={"cell primary left"}>
                                            {category.sectionName}
                                        </div>
                                        <div
                                            className={
                                                "cell primary large-only"
                                            }
                                        >
                                            Standard
                                        </div>
                                        <div
                                            className={
                                                "cell primary large-only"
                                            }
                                        >
                                            Pro
                                        </div>
                                        <div
                                            className={
                                                "cell primary large-only"
                                            }
                                        >
                                            Enterprise
                                        </div>
                                    </div>
                                    {category.features.map((feature, index) => (
                                        <div
                                            key={`pf-${index}`}
                                            className={"row"}
                                        >
                                            <div className={"cell left"}>
                                                <span>
                                                    {feature.featureName}
                                                </span>
                                            </div>
                                            <div className={"cell"}>
                                                <div
                                                    className={
                                                        "mobile-plan-name"
                                                    }
                                                >
                                                    Standard
                                                </div>
                                                <span>
                                                    {renderFeatureGridItem(
                                                        feature.standard
                                                    )}
                                                </span>
                                            </div>
                                            <div className={"cell"}>
                                                <div
                                                    className={
                                                        "mobile-plan-name"
                                                    }
                                                >
                                                    Pro
                                                </div>
                                                <span>
                                                    {renderFeatureGridItem(
                                                        feature.pro
                                                    )}
                                                </span>
                                            </div>
                                            <div className={"cell"}>
                                                <div
                                                    className={
                                                        "mobile-plan-name"
                                                    }
                                                >
                                                    Enterprise
                                                </div>
                                                <span>
                                                    {renderFeatureGridItem(
                                                        feature.custom
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </section>
                            ))}
                        </div>
                    </div>
                </Constrained>
            </Container>
        </Page>
    )
}

export default Pricing
